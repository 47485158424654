<template>
  <v-dialog
    v-model="show"
    scrollable
    max-width="600px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          Pesquisar
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-switch
            v-model="confirmPresenceMode"
            class="mt-3 mr-5"
            dense
            inset
          >
            <template v-slot:label>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <small 
                    class="ml-n3"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Modo confirmar presença
                  </small>
                </template>
                <span>Quando ativado abre automaticamente o inscrito encontrado na pesquisa</span>
              </v-tooltip>
            </template>
          </v-switch>
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-form @submit="search">
        <v-card-text class="pt-4">
          <!-- <v-row>
            <v-col>
              <v-switch
                v-model="confirmPresenceMode"
                label="Modo confirmar presença"
              ></v-switch>
            </v-col>
          </v-row> -->
          
            <validation-observer ref="observer">
              <v-row>
                <v-col md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="name"
                  >
                    <v-text-field
                      v-model="filters.name"
                      hide-details="auto"
                      label="Nome"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
                <v-col md="6">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="code"
                  >
                    <v-text-field
                      v-model="filters.code"
                      hide-details="auto"
                      label="Código"
                      outlined
                      dense
                      :error-messages="errors"
                    />
                  </validation-provider>
                </v-col>
              </v-row>
            </validation-observer>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="teal"
            dark
            type="submit"
          >
            <v-icon 
              v-text="'mdi-account-search'"
              left
            />
            Pesquisar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>

    <dialog-multiple-enrolled
      v-model="showDialogMultipleEnrolled"
      :enrolled="multipleEnrolled"
      @select="onSelectMultipleEnrolled"
    />
  </v-dialog>
</template>

<script>
  import enrolledApi from '@/api/enrolled'

  export default {

    components: {
      DialogMultipleEnrolled: () => import('@/components/dialog/events/DialogMultipleEnrolled'),
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
    },

    data () {
      return {
        confirmPresenceMode: true,
        showDialogMultipleEnrolled: false,
        multipleEnrolled: [],
        loading: false,
        filters: {},
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
    },

    methods: {

      async search (event) {
        try {
          event.preventDefault()
          this.$refs.observer.reset()
          this.loading = true
          
          if (this.confirmPresenceMode) {
            this.multipleEnrolled = []

            const response = await enrolledApi.list(this.$route.params.id, this.filters)

            this.filters = {}

            if (response.data.count > 1) {
              this.multipleEnrolled = response.data.enrolled
              this.showDialogMultipleEnrolled = true
            } else {
              this.$emit('show-dialog-enrolled', response.data.enrolled[0].id)
            }
          } else {
            this.$emit('search', this.filters)
            this.show = false
          }
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          const color = e.response.status < 500 ? 'warning' : 'error'
          this.$snackbar.show({ color, message: this.$apiError._(e) })
        } finally {
          this.loading =false
        }
      },

      onSelectMultipleEnrolled (enrolledId) {
        this.showDialogMultipleEnrolled = false
        this.multipleEnrolled = []
        this.$emit('show-dialog-enrolled', enrolledId)
      },
    },

  }
</script>
